import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/posts.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The problem`}</h1>
    <p>{`Recently one of my colleagues came across a problem when he wanted to create an Angular application which needed to have different configuration values between environments.`}</p>
    <p>{`In 2016, Jurgen Van de Moere wrote a `}<a href="https://www.jvandemo.com/how-to-configure-your-angularjs-application-using-environment-variables/" target="_blank">{`blogpost`}</a>{` which explained how to create environment-agnostic applications with AngularJS.
A year later, Rich Franzmeier explained in his `}<a href="https://www.intertech.com/Blog/deploying-angular-4-apps-with-environment-specific-info/" target="_blank">{`blogpost`}</a>{` a solution for Angular applications which was based on Jurgen’s post.
Although both solutions work perfectly, they both have some downsides to it.`}</p>
    <p>{`Nowadays we want to push devops to all teams.
This implies that our applications should be immutable, and that everything should be automated (as much as possible).
If we want to deploy our application we have to overwrite our configuration file manually, so this was the main disadvantage of the solution they proposed.`}</p>
    <h1>{`The solution`}</h1>
    <p>{`Our solution is build on top of theirs, and should be able to work for all kinds of frameworks (AngularJS, Angular, React, Vue,...).`}</p>
    <p>{`Here is how we did it.`}</p>
    <h2>{`Code setup`}</h2>
    <p>{`We started with an `}<inlineCode parentName="p">{`env.js`}</inlineCode>{` file which contains all environment-specific configuration.
This file will expose the data as global variables.
One could set the URL where our API is hosted like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`(function (window) {
  window.__env = window.__env || {};

  // API url
  window.__env.apiUrl = 'http://dev.your-api.com';
}(this));
`}</code></pre>
    <p>{`Next up is to expose this variable to our web application, which in our case is an Angular app.
We created an injectable `}<inlineCode parentName="p">{`Configuration`}</inlineCode>{` class, which will have a getter to retrieve our API URL.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import {Injectable} from '@angular/core';

function _env(): any {
  // return the native window obj
  return window.__env;
}

@Injectable()
export class Configuration {
  
  get apiUrl(): any {
    return _env().apiUrl;
  }
  
}
`}</code></pre>
    <p>{`All we need to do now is to inject an instance of the `}<inlineCode parentName="p">{`Configuration`}</inlineCode>{` class in the class where we need the info.`}</p>
    <h2>{`Deployment setup`}</h2>
    <p>{`After building our Angular app, we need to package everything together.
Since we want to make everything immutable, the obvious choice here is to use Docker.
While starting a container, we can specify environment variables.
This is where the second part of our solution starts.`}</p>
    <p>{`Using the `}<inlineCode parentName="p">{`envsubst`}</inlineCode>{` bash command we're going to convert a template file into an `}<inlineCode parentName="p">{`env.js`}</inlineCode>{` file which contains all our data.
Our `}<inlineCode parentName="p">{`env.js.tpl`}</inlineCode>{` file looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`(function (window) {
  window.__env = window.__env || {};

  // API url
  window.__env.apiUrl = '$API_URL';
}(this));
`}</code></pre>
    <p>{`To perform this conversion we need to create a `}<inlineCode parentName="p">{`startup.sh`}</inlineCode>{` script which will actually execute the `}<inlineCode parentName="p">{`envsubst`}</inlineCode>{` command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`envsubst < /usr/share/nginx/env.js.tpl > /usr/share/nginx/html/env.js

nginx -g 'daemon off;'
`}</code></pre>
    <p>{`Now we need to create a `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` which in turn will be used to create our Docker image.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-dockerfile"
      }}>{`FROM nginx:1.13-alpine

COPY dist /usr/share/nginx/html

COPY env.js.tpl /usr/share/nginx/env.js.tpl
COPY startup.sh /usr/local/bin/startup.sh

ENTRYPOINT ["/bin/tini", "--", "/usr/local/bin/startup.sh"]
`}</code></pre>
    <h1>{`Conclusion`}</h1>
    <p>{`This is how we managed to build our application only once, and deploy across numerous environments.
Although it is not as immutable as we want it to be, at least we have automated the process to get rid of most human errors.
And this way we can easily create a separate environment if there is a bug in production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      